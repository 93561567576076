#section-how-it-works {
  padding-top: 10rem;

  .row-how-it-works {
    position: relative;
    padding: 9rem 0;
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .img-how-it-works-phone {
    position: absolute;
    left: -8rem;
    right: 0;
    top: 0;
    bottom: 0;
    background: url('../../assets/img/app-phone.png') no-repeat left center;
    background-size: contain;
  }

  .how-it-work-item {
    display: flex;
    padding: 1rem 0;

    &:nth-child(2),
    &:nth-child(3) {
      padding-left: 6rem;
    }

    img {
      width: 13rem;
      height: 13rem;
    }

    .hiwi-description {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: $font-family-secondary;

      h3 {
        color: $main-text-color;
        font-size: $mxlFontSize;
        font-weight: 400;
        font-family: $font-family-secondary;
      }
      p {
        color:  $color-description;
        font-size: $baseFontSize;
        font-weight: 300;
        line-height: 2rem;
        max-width: 34rem;
      }
    }
  }
}

@media screen and (max-width: $breakpoint_M) {
  #section-how-it-works {
    .img-how-it-works-phone {
      left: -20rem;
    }
  }
}

@media screen and (max-width: $breakpoint_S) {
  #section-how-it-works {

    .row-how-it-works {
      padding: 0;
    }

    .img-how-it-works-phone {
      position: relative;
      left: 0;
      background: url("../../assets/img/app-phone.png") no-repeat center;
      background-size: contain;
      height: 60rem;
      width: 100%;
    }

    .how-it-work-wrapper {
      margin-top: -6rem;
    }

    .how-it-work-item {
      padding: 1rem 4rem;
      &:nth-child(2),
      &:nth-child(3) {
        padding-left: 4rem;
      }
    }
  }
}

@media screen and (max-width: $breakpoint_XS) {
  #section-how-it-works {
    .section-header {
      margin-bottom: 2rem;
    }
    .img-how-it-works-phone {
      position: relative;
      top: -5rem;
    }
  }
}