.header {
  min-height: 100vh;
  background: url("../img/hero-img.jpg") no-repeat right;
  background-size: auto 100%;
  padding: 6.6rem 0 0;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(90deg, rgba(255,255,255,1) 45%, rgba(255,255,255,0) 80%);
  }

  .container {
    height: 100%;

    .row {
      .product-description {
        font-family: $font-family-secondary;

        .product-title {
          font-family: $font-family-secondary;
          font-weight: 700;
          font-size: $xxxxlgFontSize;
          color: $color-primary;
          padding-top: 6rem;
          margin-bottom: 4.5rem;
        }

        .product-subtitle {
          font-size: $xxlgFontSize;
          font-weight: 700;
          color: $main-text-color;
          font-family: $font-family-secondary;
          margin-bottom: 0;
          line-height: 4rem;
        }

        .product-details {
          font-size: $lgxFontSize;
          font-weight: 700;
          font-family: $font-family-secondary;
          color: $main-text-color;
        }

        .product-info {
          font-size: $mlFontSize;
          font-weight: 300;
          color: $color-description;
          margin: 2.5rem 0 4rem 0;
          line-height: 2.4rem;
        }

        .payment-option-gp {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 6.5rem;

          img {
            height: 5.5rem;
            width: 19rem;
          }

          .payment-info {
            font-size: $mxlFontSize;
            font-weight: 700;
            color: $main-text-color;
            margin: 0 0 0 1rem;

            span {
              font-weight: 700;

              &.payment-info-mobile {
                text-decoration: underline;
                text-transform: uppercase;
                color: $color-primary;
              }
            }
          }
        }

        .payment-option-code {
          .payment-code {
            font-family: $font-family-secondary;
            color: $main-text-color;
            font-size: $xlFontSize;
            font-weight: 700;
          }

          .code {
            color:  $color-primary;
            font-weight: 700;
            font-size: $xxxxxlgFontSize;
            line-height: 6rem;
            margin: 0;
          }

          .payment-info {
            font-family: $font-family-secondary;
            color:  $main-text-color;
            font-size: $lgFontSize;
            font-weight: 700;
            .payment-info-mobile {
              display: none;
            }
          }
        }

        .typing {
          color: $color-primary;
        }
      }

      .scroll-icon {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 1rem 0 0 0;
      }
    }
  }
}

@media screen and (max-width: $breakpoint_L) {
  .header {
    min-height: 90rem;
    background: url("../img/hero-img.jpg") no-repeat 70%;
    background-size: cover;
    &:before {
      background-image: linear-gradient(90deg, rgba(255,255,255,1) 55%, rgba(255,255,255,0) 90%);
    }
  }
}

@media screen and (max-width: $breakpoint_M) {
  .header {
    &:before {
      background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
    }
  }
}

@media screen and (max-width: $breakpoint_S) {
  .header {
    background: none;
    padding: 5rem 0 3rem 0;

    .container {
      height: 100%;
      text-align: center;

      .row {
        .product-description {
          .product-title {
            margin-bottom: 3rem;
            font-size: $xxxlgFontSize;
          }

          .product-subtitle {
            font-size: $xlFontSize;
            line-height: 3rem;

          }

          .product-details {
            font-size: $mxlFontSize;
            margin-bottom: 3rem;
          }

          .product-info {
            margin-bottom: 5rem;
            max-width: 62rem;
            font-size: $mlFontSize;
            line-height: 2rem;
          }

          .background-img-mobile {
            min-height: 75rem;
            background: url("../img/mobile-hero.jpg") no-repeat center;
            background-size: contain;
          }

          .payment-option-gp {
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 4rem;

            img {
              margin-bottom: 2.5rem;
            }

            .payment-info {
              width: 100%;
              font-size: $mxlFontSize;
              margin: 0 0 0 2rem;

              span {
                text-transform: none;
                text-decoration: none;
                color: $main-text-color;
              }

              .payment-info-mobile {
                text-transform: uppercase;
                text-decoration: underline;
                color: $color-primary;
              }
            }
          }

          .payment-option-code {
            .payment-code {
              font-size: $lgxFontSize;
              margin-bottom: 2rem;
            }

            .code {
              font-size: $xxxxxxlgFontSize;
              margin-bottom: 2rem;
            }

            .payment-info {
              font-size: $mmlFontSize;

              .payment-info-mobile {
                color: $main-text-color;
                display: inline-block;
              }
            }
          }
        }

        .scroll-icon {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint_XS) {
  .header {
    .container {
      .row {
        .product-description {
          .product-details {
            display: flex;
            flex-direction: column;
          }

          .background-img-mobile {
            min-height: 60rem;
          }

          .payment-option-gp {
            margin-bottom: 2rem;
            .payment-info {
              span {
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}
