.footer {
  font-family: $font-family-secondary;
  color:  $color-description;
  font-size: $baseFontSize;
  font-weight: 300;
  line-height: 1.7;
  padding-top: 10rem;

  .logo-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    .logo-img {
      width: 3.8rem;
      height: 3.8rem;
      background: $color-primary url("../img/footer-logo.png") no-repeat center;
    }

    p {
      font-family: $font-family-secondary;
      font-size: $mlFontSize;
      font-weight: 600;
      margin: 0 0 0 1rem;
      text-transform: uppercase;

      a {
        color: $main-text-color;
      }
    }
  }

  .footer-description {
    p {
      margin-bottom: 2rem;
    }
  }

  .footer-links {
    margin-bottom: 3rem;

    a {
       color: $color-description;
    }
  }
}

@media screen and(max-width: $breakpoint_S) {
  .footer {
    font-size: $mFontSize;

    .logo{
      order: 2;
      .logo-wrapper {
        margin-top: 3rem;
      }
    }

    .footer-description {
      order: 3;

      .d-xl-none {
        display: none;
      }
    }

    .footer-links {
      order: 1;
    }
  }
}