html {
 font-size: $baseRootFontSize;
}

.scroll-top {
 right: 2rem;
 bottom: 2rem;
 width: 5rem;
 height: 5rem;
 line-height: 5rem;
}

@media screen and (max-width: $breakpoint_S) {
 html {
  font-size: 55%;
 }
 // remove animation for mobile
 [data-aos],
 [data-aos][data-aos],
 [data-aos][data-aos][data-aos-delay],
 [data-aos][data-aos][data-aos-easing],
 [data-aos][data-aos][data-aos-duration],
 body[data-aos-delay] [data-aos],
 body[data-aos-duration] [data-aos].aos-animate,
 body[data-aos][data-aos][data-aos-delay].aos-animate,
 body[data-aos][data-aos].aos-animate,
 body[data-aos-easing][data-aos],
 body[data-aos-duration] [data-aos] {
  -webkit-transform: translate3d(0, 0, 0)!important;
  transform: translate3d(0, 0, 0)!important;
  -webkit-transition-duration: 0ms!important;
  transition-duration: 0ms!important;
   -webkit-transition-delay: 0ms!important;
   transition-delay: 0ms!important;
  opacity: 1!important;
 }
}
