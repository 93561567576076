.section-header {
  max-width: 70%;
  margin-bottom: 5rem;

  h2 {
    font-family: $font-family-secondary;
    color:  $main-text-color;
    font-size: $xxlgFontSize;
    font-weight: 600;
    line-height: 4.4rem;
    margin-bottom: 0;
  }

  h3 {
    font-family: $font-family-secondary;
    color:  $main-text-color;
    font-size: $lgxFontSize;
  }

  p {
    color: $color-description;
    font-size: $mlFontSize;
    font-weight: 400;
    padding: 0 8rem;
    line-height: 2.8rem;
    margin-top: 2.2rem;
  }
}

@media screen and (max-width: $breakpoint_M) {
  .section-header {
    max-width: 100%;

    h2 {
      font-size: $xlFontSize;
    }

    h3 {
      font-size: $mxlFontSize;
    }

    p {
      padding: 0 0;
    }
  }
}

