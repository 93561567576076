.section-info {
  min-height: 40rem;
  padding-bottom: 0;

  .row {
    margin-left: -25px;
    margin-right: -25px;
    align-items: center;

    .col-md-6 {
      padding-left: 25px;
      padding-right: 25px;
    }

    .card-body {
      font-family: $font-family-secondary;

      .card-title {
        font-family: $font-family-secondary;
        font-size: $lgFontSize;
        color: $main-text-color;
        font-weight: 600;
        margin-bottom: 4rem;
      }

      .card-description {
        color: $color-description;
        font-size: $baseFontSize;
        line-height: 2.4rem;
        font-weight: 300;
        margin-bottom: 2.5rem;
      }
    }
  }
}
#section-thrive-with-us {
  margin-bottom: 14rem;
}

@media screen and (max-width: $breakpoint_S) {
  #section-potential,
  #section-thrive-with-us {
    .container {
      .row {
        flex-direction: column-reverse;
      }
    }
  }

  .section-info {
    .row {
      .card-body {
        .card-title {
          text-align: center;
        }
        .card-description {
          font-size: $mlFontSize;
          line-height: 2.8rem;
        }
      }
    }
  }
}