#section-no-phone {
  .container {
    .row {
      .payment-option-code {
        display: flex;
        align-items: center;
        .payment-code {
          font-family: $font-family-secondary;
          color: $main-text-color;
          font-size: $xlFontSize;
          font-weight: 700;
          margin-bottom: 1rem;
        }

        .code {
          color:  $color-primary;
          font-weight: 700;
          font-size: $xxxxxlgFontSize;
          line-height: 6rem;
          margin: 0;
        }

        .payment-info {
          font-family: $font-family-base;
          color:  $main-text-color;
          font-size: $lgFontSize;
          font-weight: 700;
          line-height: 3.2rem;
          margin-top: 2rem;
        }
      }

      .arrow-wrapper {
        justify-content: center;
      }

      .card-body {
        display: flex;
        align-items: center;
        justify-content: center;
        .phone-img {
          height: 33rem;
          width: 33rem;
          background:  url('../../assets/img/phone.jpg') no-repeat center;
          background-size: contain;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint_M) {
  #section-no-phone {
    .container {
      .row {
        .payment-option-code {

          .code {
            font-size: $xxxlgFontSize;
          }

          .payment-info {
            font-size: $mxlFontSize;
          }
        }
        .card-body {
          .phone-img {
            height: 29rem;
            width: 29rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint_S) {
  #section-no-phone {
    .container {
      .row {
        flex-direction: column-reverse;
        .payment-option-code {
          justify-content: center;
          text-align: center;
          .code {
            font-size: $xxxxxlgFontSize;
          }
          .card-body {
            text-align: center;
            .phone-img {
              height: 33rem;
              width: 33rem;
            }
          }
        }

        .arrow-wrapper {
          margin: 2rem 0 1rem 0;
          img {
            transform: rotate(90deg);
            width: 8rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint_XS) {
  #section-no-phone {
    padding-top: 0;
  }
}