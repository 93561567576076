#section-transparency {

  .section-header {
    margin-bottom: 1rem;
  }

  .transparency-phone {
    padding: 6rem 0 10em;
    position: relative;

    .img-transparency-phone {
      position: absolute;
      left: 0;
      right: 1.75rem;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url('../../assets/img/app-phone.png') no-repeat center;
      background-size: contain;
    }

    .transparency-item-left {
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      .transparency-item {
        margin-right: 6rem;

        &:nth-child(2) {
          margin-right: 12rem;
        }
      }
    }

    .transparency-item-right {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .transparency-item {
        margin-left: 6rem;

        &:nth-child(2) {
          margin-left: 12rem;
        }
      }
    }
  }

  .transparency-phone-mobile {
    display: none;
  }

  .transparency-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 20rem;
    height: 13.5rem;
    margin-top: 4rem;
    margin-bottom: 4rem;

    .transparency-item-img {
      flex: 1;
      img {
        width: 9rem;
      }
    }
    span  {
      font-family: $font-family-secondary;
      color:$main-text-color;
      font-size: $mmlFontSize;
      font-weight: 400;
    }
  }
}

@media screen and (max-width: $breakpoint_M) {
  #section-transparency {
    .transparency-item {
      min-width: 16rem;
    }
  }
}
@media screen and (max-width: $breakpoint_S) {
  #section-transparency {

    .transparency-phone {
      height: 60rem;

      .transparency-item-left,
      .transparency-item-right {
        display: none
      }
    }

    .transparency-phone-mobile {
      display: flex;
    }

    .transparency-item {
      height: 15rem;

      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 8rem;
      }

      span {
        font-size: $mxlFontSize;
      }
    }
  }
}

@media screen and (max-width: $breakpoint_XS) {
  #section-transparency {
    .transparency-phone {
      .img-transparency-phone {
        position: absolute;
        top: -20rem;
      }
    }

    .transparency-phone-mobile {
      margin-top: -20rem;
    }

    .transparency-item {
      min-width: 10rem;
      line-height: 3rem;
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 6rem;
      }
      span {
        font-size: $mmlFontSize;
        text-align: center;
      }
    }
  }
}