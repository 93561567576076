.overlay-section {
  min-height: 50rem;
  display: flex;
  align-items: center;

  &.join-us-section {
    background-image: url("../../assets/img/join-our-team.jpg");
  }

  &.download-app-section {
    background-image: url("../../assets/img/download-our-app.jpg");
  }

  .overlay {
    background-image: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7));
  }

  .overlay-title {
    font-family: $font-family-secondary;
    color: $color-bg-body;
    font-size: $xxlgFontSize;
    font-weight: 600;
    margin-bottom: 1.8rem;
  }

  .overlay-description {
    font-family: $font-family-secondary;
    color: $color-bg-body;
    font-size: $mlFontSize;
    font-weight: 300;
    margin-bottom: 4.4rem;
    line-height: 2.6rem;
  }

  .overlay-btn {
    font-family: $font-family-secondary;
    font-size: $baseFontSize;
    font-weight: 300;
    line-height: 4rem;
  }
  
  .btn-mobile {
    display: none;
  }
}

@media screen and (max-width: $breakpoint_S) {
  .overlay-section {
    min-height: 65rem;
    &.download-app-section {
      background-position: 70%;
    }
    .overlay-title {
      font-size: $xxlFontSize;
      margin-bottom: 2rem;
    }

    .overlay-description {
      font-family: $font-family-secondary;
      color: $color-bg-body;
      font-size: $mlFontSize;
      font-weight: 300;
      margin-bottom: 6rem;
      line-height: 3rem;
    }

    .overlay-btn {
      font-family: $font-family-secondary;
      font-size: $mFontSize;
      font-weight: 300;
      line-height: 6rem;
    }
  }
}

@media screen and (max-width: $breakpoint_XS) {
  .overlay-section {
    min-height: 65rem;

    .overlay-btn {
      font-family: $font-family-secondary;
      font-size: $mFontSize;
      font-weight: 300;
      white-space: normal;
    }

    .btn-desktop {
      display: none;
    }

    .btn-mobile {
      display: block;
    }
  }
}