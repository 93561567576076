#section-testimonials {
  .card-body {
    padding: 2rem 3rem;
    font-size: $baseFontSize;

    .rating {
      margin-bottom: 1.75rem;
    }

    .text-quoted {
      font-weight: 500;
      margin-bottom: 2.1rem;
    }
  }
}