#section-faq {
  font-family: "Montserrat", sans-serif;
  .faq-title {
    font-family: "Montserrat", sans-serif;
    font-size: $lgFontSize;
    padding-bottom: 1.5rem;
  }
  .accordion {
    margin-bottom: 6rem;
    .card {
      .card-title a {
        font-family: "Montserrat", sans-serif;
        font-size: $mmlFontSize;
      }
      .card-body {
        font-size: $mlFontSize;
        line-height: 2.5rem;
        padding: 3.5rem 2rem;
      }
    }
  }
}