nav {
  .navbar-left {
    flex: 1;
    padding-left: 15px;
  }

  .language-selector-container {
    display: flex;
    .language {
      padding: 0 1rem;
      font-size: $baseFontSize;
      line-height: $baseFontSize ;
      text-transform: uppercase;
      cursor: pointer;
      font-weight: bolder;
      color: $main-text-color;
      &.active {
        color: $color-primary;
        font-weight: bold;
      }
    }
    .lang-choice-desktop {
      &:first-child {
        border-right: 1px solid $main-text-color;
      }
    }
    .lang-choice-mobile {
      display: none;

    }
  }
  .nav.nav-navbar {
    &:not(.nav-inline) {
      width: initial;
    }
    .nav-link {
      padding-left: 15px;
      padding-right: 15px;
      color: $main-text-color;
    }
  }
}

@media screen and (max-width: $breakpoint_S) {
  nav {
    .language-selector-container {
      .lang-choice-desktop {
        display: none;
      }
      .lang-choice-mobile {
        display: block;
        &:nth-child(3) {
          border-right: 1px solid $main-text-color;
        }
      }
    }
  }
}