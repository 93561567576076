.logo-wrapper {
  display: flex;
  align-items: center;

  .logo-img {
    width: 3.8rem;
    height: 3.8rem;
    background: $color-primary url("../img/footer-logo.png") no-repeat center;
  }

  p {
    font-family: $font-family-secondary;
    font-size: $mlFontSize;
    font-weight: 600;
    margin: 0 0 0 1rem;
    text-transform: uppercase;
    color: $main-text-color;
  }
}
